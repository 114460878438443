import { fetchWithToken } from 'utils/fetch';
import { urlEncodeBody } from 'utils/requests';
import { mapDocumentsTree, mapListingDocuments } from 'utils/mapping';
import { Doctypes } from './api';

export async function getFoldersWithDocuments(): Promise<AnyNode[]> {
  const response = await fetchWithToken('/documents');
  const data = await response.json();

  return mapDocumentsTree(data);
}

export async function createFolder(values: { name: string; parentId?: number }): Promise<void> {
  await fetchWithToken('/documents/folder', {
    method: 'POST',
    body: urlEncodeBody({ folder: values.name, parent_id: values.parentId })
  });
}

export async function uploadDocument(values: {
  file: File;
  folder?: string;
  listing?: string;
  doctype?: Doctypes;
  draft?: boolean;
  base_price?: number;
  earnest_money_1?: number;
  earnest_money_2?: number;
  contract_closing_date?: string;
  closing_cost_credit?: number;
  effective_date?: string;
  attorney_review_deadline?: string;
  inspection_due_deadline?: string;
  mortgage_contingency_cleartoclose_deadline?: string;
  extras_total?: number;
  sales_commission?: number;
  purchase_commission?: number;
  em_role_specific?: string;
  earnest_money_1_deposit?: number;
  earnest_money_2_deposit?: number;
  earnest_money_3_deposit?: number;
  earnest_money_4_deposit?: number;
}): Promise<number> {
  // presign document
  const { presigned_url: presignedUrl, document_id: documentId } = await (
    await fetchWithToken('/documents', {
      method: 'POST',
      body: urlEncodeBody({
        filename: values.file.name,
        size: values.file.size,
        doctype_id: values.doctype,
        folder_id: values.folder,
        listing_id: values.listing,
        is_draft: values.draft ? 1 : 0,
        base_price: values.base_price,
        earnest_money_1: values.earnest_money_1,
        earnest_money_2: values.earnest_money_2,
        contract_closing_date: values.contract_closing_date,
        closing_cost_credit: values.closing_cost_credit,
        effective_date: values.effective_date,
        attorney_review_deadline: values.attorney_review_deadline,
        inspection_due_deadline: values.inspection_due_deadline,
        mortgage_contingency_cleartoclose_deadline:
          values.mortgage_contingency_cleartoclose_deadline,
        extras_total: values.extras_total,
        sales_commission: values.sales_commission,
        purchase_commission: values.purchase_commission,
        em_role_specific: values.em_role_specific,
        earnest_money_1_deposit: values.earnest_money_1_deposit,
        earnest_money_2_deposit: values.earnest_money_2_deposit,
        earnest_money_3_deposit: values.earnest_money_3_deposit,
        earnest_money_4_deposit: values.earnest_money_4_deposit
      })
    })
  ).json();
  // upload file to s3
  await fetch(presignedUrl, { method: 'PUT', body: values.file });
  // confirm upload success
  await fetchWithToken(`/documents/${documentId}`, { method: 'PUT' });

  return documentId;
}

export async function deleteDocument(id: string): Promise<void> {
  await fetchWithToken(`/documents/${id}`, { method: 'DELETE' });
}

export async function deleteFolder(id: string): Promise<void> {
  await fetchWithToken(`/documents/folder/${id}`, { method: 'DELETE' });
}

export async function downloadDocument(
  id: string | number
): Promise<{ name: string; url: string }> {
  const query = new URLSearchParams();
  query.append(`ids[0]`, String(id));

  const response = await fetchWithToken('/documents/download', {
    method: 'GET',
    query: query.toString()
  });
  const data = await response.json();

  return { name: data.documents[0].name, url: data.documents[0].url };
}

export async function shareDocuments(value: {
  ids: number[];
  teamMembers?: number[];
  listingMembers?: number[];
  customEmails?: string[];
}): Promise<void> {
  await fetchWithToken('/documents/share', { method: 'POST', body: urlEncodeBody(value) });
}

export async function getDocuments(listingId: string): Promise<WorkspaceDocument[]> {
  const response = await fetchWithToken(`/documents/listing/${listingId}`);

  const data = await response.json();

  return mapListingDocuments(data.documents);
}

export async function deleteMultipleDocuments(ids: number[]): Promise<void> {
  const body = new URLSearchParams();
  ids.forEach((id, idx) => {
    body.append(`ids[${idx}]`, String(id));
  });
  await fetchWithToken('/documents/multiple', { method: 'DELETE', body });
}

export async function downloadDocumentsArchive(ids: number[]): Promise<string> {
  const query = new URLSearchParams();
  ids.forEach((id, idx) => {
    query.append(`ids[${idx}]`, String(id));
  });
  const response = await fetchWithToken('/documents/zip', {
    method: 'GET',
    query: query.toString()
  });

  const downloadUrl = URL.createObjectURL(await response.blob());

  return downloadUrl;
}
