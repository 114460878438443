import { DropdownOption } from 'components';
import { groupBy } from 'lodash';

export const documentTypesData = [
  {
    id: 1,
    key: 'td_contract',
    name: 'Contract',
    category: 'contract_amendments'
  },
  {
    id: 2,
    key: 'td_exhibit',
    name: 'Exhibit',
    category: 'contract_amendments'
  },
  {
    id: 3,
    key: 'td_rider',
    name: 'Rider',
    category: 'contract_amendments'
  },
  {
    id: 4,
    key: 'td_disclosures',
    name: 'Disclosures',
    category: 'contract_amendments'
  },
  {
    id: 5,
    key: 'td_contractamendment',
    name: 'Amendment',
    category: 'contract_amendments'
  },
  {
    id: 6,
    key: 'td_escrowagreement',
    name: 'Escrow Agreement',
    category: 'contract_amendments'
  },
  {
    id: 7,
    key: 'td_preapproval',
    name: 'Pre-approval',
    category: 'contract_amendments'
  },
  {
    id: 8,
    key: 'td_emreceipt',
    name: 'EM Receipt',
    category: 'contract_amendments'
  },
  {
    id: 9,
    key: 'td_misc',
    name: 'Miscellaneous',
    category: 'contract_amendments'
  },
  {
    id: 10,
    key: 'ld_listingagreement',
    name: 'Listing Agreement',
    category: 'listing_documents'
  },
  {
    id: 11,
    key: 'ld_propertydisclosure',
    name: 'Property Disclosure',
    category: 'listing_documents'
  },
  {
    id: 12,
    key: 'ld_radondisclosure',
    name: 'Radon Disclosure',
    category: 'listing_documents'
  },
  {
    id: 13,
    key: 'ld_leaddisclosure',
    name: 'Lead Disclosure',
    category: 'listing_documents'
  },
  {
    id: 14,
    key: 'ld_molddisclosure',
    name: 'Mold Disclosure',
    category: 'listing_documents'
  },
  {
    id: 15,
    key: 'ld_disclosure',
    name: 'Disclosure',
    category: 'listing_documents'
  },
  {
    id: 16,
    key: 'ld_other',
    name: 'Miscellaneous',
    category: 'listing_documents'
  },
  {
    id: 17,
    key: 'ld_offer',
    name: 'Offer',
    category: 'listing_documents'
  },
  {
    id: 18,
    key: 'fcd_policy',
    name: 'Policy',
    category: 'final_closing_documents'
  },
  {
    id: 19,
    key: 'fcd_waived',
    name: 'Waived Title',
    category: 'final_closing_documents'
  },
  {
    id: 20,
    key: 'fcd_closingstat',
    name: 'Closing Statement',
    category: 'final_closing_documents'
  },
  {
    id: 21,
    key: 'fcd_postescrow',
    name: 'Post-Closing Agreement',
    category: 'final_closing_documents'
  },
  {
    id: 22,
    key: 'fcd_misc',
    name: 'Miscellaneous',
    category: 'final_closing_documents'
  },
  {
    id: 23,
    key: 'fcd_punchlist',
    name: 'Punch List',
    category: 'final_closing_documents'
  },
  {
    id: 24,
    key: 'fcd_warranty',
    name: 'Warranty',
    category: 'final_closing_documents'
  },
  {
    id: 25,
    key: 'fcd_deed',
    name: 'Deed',
    category: 'final_closing_documents'
  }
];

export const getDocumentTypeOptions = (documentTypes = documentTypesData): DropdownOption[] => {
  let options: DropdownOption[] = [];
  const groups = groupBy(documentTypes, 'category');
  Object.entries(groups).forEach(([groupKey, items]) => {
    let categoryName = '';
    switch (groupKey) {
      case 'contract_amendments':
        categoryName = 'Contract Amendments';
        break;
      case 'final_closing_documents':
        categoryName = 'Final Closing Documents';
        break;
      case 'listing_documents':
        categoryName = 'Listing Documents';
        break;
      default:
        break;
    }
    if (categoryName) {
      options.push({
        value: groupKey,
        text: `--- ${categoryName} ---`,
        disabled: true,
        noSearch: true
      });
    }
    options = [
      ...options,
      ...items.map(item => ({
        value: item.id,
        text: item.name
      }))
    ];
  });
  return options;
};

export const documentTypeOptions = getDocumentTypeOptions();
