import { snakeCase } from 'lodash';
import { DropdownOption, AutocompleteOption } from 'components';
import { fetchWithToken } from 'utils/fetch';
import { urlEncodeBody } from 'utils/requests';
import keysToCamel from 'utils/keysToCamel';
import { mapWorkspaceNotes, mapWorkspaceActivityLog, mapWorkspaceOffer } from 'utils/mapping';
import { CalendarItem } from 'views/CalendarPage/calendar';
import { mapCalendarData, mapDocuments } from 'views/CalendarPage/mapping';

export async function getOffers(listingId: string): Promise<WorkspaceOffer[]> {
  const response = await fetchWithToken(`/listings/offers/${listingId}`, {
    method: 'GET'
  });
  const data = await response.json();
  const offers = data.offers.map(mapWorkspaceOffer);

  return offers;
}

export async function changeOfferStatus(payload?: WorkspaceOffer): Promise<void> {
  const formData = urlEncodeBody({
    buy_process_id: payload?.buyProcessId,
    status: payload?.status
  });
  await fetchWithToken(`/listings/offers`, {
    method: 'POST',
    body: formData
  });
}

export async function getMembers(listingId: string): Promise<WorkspaceMember[]> {
  const response = await fetchWithToken(`/people/listing/${listingId}`, {
    method: 'GET'
  });
  const data = await response.json();

  return keysToCamel(data);
}

export async function updateParticipant(values): Promise<void> {
  const body = Object.keys(values).reduce((accum, current) => {
    // eslint-disable-next-line no-param-reassign
    accum[snakeCase(current)] = values[current];
    return accum;
  }, {});

  await fetchWithToken(`/people`, {
    method: 'PUT',
    body: urlEncodeBody(body)
  });
}

export async function removeParticipant(listingId: string, memberId) {
  await fetchWithToken(`/people/listing/${listingId}`, {
    method: 'DELETE',
    body: urlEncodeBody({ people_ids: memberId })
  });
}

export async function getWorksapceCalendarData(
  listingId: string
): Promise<{ calendar: CalendarItem[]; documents: DropdownOption[] }> {
  const response = await fetchWithToken(`/calendar/listing/${listingId}`);

  const data = await response.json();

  return {
    calendar: mapCalendarData(data.calendar),
    documents: mapDocuments(data.docTypes)
  };
}

export async function getNotes(listingId: string): Promise<WorkspaceNote[]> {
  const response = await fetchWithToken(`/notes/${listingId}`);

  const data = await response.json();

  return mapWorkspaceNotes(data);
}

export async function addNote(payload: {
  listingId: string;
  text: string;
}): Promise<WorkspaceNote[]> {
  const response = await fetchWithToken(`/notes`, {
    method: 'PUT',
    body: urlEncodeBody({ note: payload.text, listing_id: payload.listingId })
  });

  const data = await response.json();

  return mapWorkspaceNotes(data);
}

export async function deleteNote(noteId: number): Promise<void> {
  const response = await fetchWithToken(`/notes/${noteId}`, { method: 'DELETE' });

  await response.json();
}

export async function getActivityLog(listingId: string): Promise<WorkspaceActivityLogItem[]> {
  const response = await fetchWithToken(`/activity_log/${listingId}`);

  const data = await response.json();

  return mapWorkspaceActivityLog(data);
}

export async function getAutocompleteEmails(): Promise<AutocompleteOption[]> {
  const response = await fetchWithToken(`/people/emails`);
  const data = await response.json();
  return data.map(item => ({ id: item.id, text: item.email }));
}

export async function getPeopleById(id: number): Promise<WorkspaceMember> {
  const response = await fetchWithToken(`/people/listings/${id}`);
  const data = await response.json();
  return keysToCamel(data.people);
}

export async function addOffer(values: {
  task_type: string;
  vendor: string;
  vendor_email: string;
  vendor_name: string;
  deadline: string;
  offered_price: string;
  note: string;
}): Promise<void> {
  const response = await fetchWithToken(`/vendors`, {
    method: 'POST',
    body: urlEncodeBody(values)
  });
  await response.json();
  // return keysToCamel(data.people);
}

export async function getVendors(type?: string) {
  let url = '/vendors';
  if (type) url += `?type=${type}`;
  const response = await fetchWithToken(url);
  const data = await response.json();
  return keysToCamel(data);
}
